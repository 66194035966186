var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"card mb-3 permissionse-card",staticStyle:{"width":"100%"},attrs:{"shadow":"hover","element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('div',{staticClass:"card-header bg-light"},[_c('h3',{staticClass:"title fw-normal mb-0 p-2"},[_vm._v("Manage Permissions")])]),_c('el-form',{ref:"permission",staticClass:"log-form",attrs:{"model":_vm.managePermissions}},[[_c('el-row',[(!_vm.isFromEntities)?_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"ptl-1"},[_c('p',{staticClass:"mb-10"},[_vm._v("Select User Role")]),_c('el-select',{attrs:{"placeholder":"Select User Type"},on:{"change":_vm.handleUserRoleSelection},model:{value:(_vm.managePermissions.userType),callback:function ($$v) {_vm.$set(_vm.managePermissions, "userType", $$v)},expression:"managePermissions.userType"}},_vm._l((_vm.userTypes),function(item){return _c('el-option',{key:item._id,attrs:{"label":_vm.getLabel(item),"value":item._id}})}),1)],1)]):_vm._e()],1)],_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-card',{staticClass:"card-body sidemenu",attrs:{"shadow":"hover"}},[_c('div',{staticClass:"pt-1 p-1"},[_c('el-form-item',[_vm._l((_vm.permissionsList),function(category,cIndex){return [_c('el-collapse',{key:cIndex,model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-collapse-item',{attrs:{"name":'' + (cIndex + 1)}},[_c('template',{slot:"title"},[_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate[cIndex],"label":category._id},on:{"change":function($event){return _vm.handleChangeCategory(
                              '' + category._id,
                              category.name
                            )}},model:{value:(_vm.checkAll[cIndex]),callback:function ($$v) {_vm.$set(_vm.checkAll, cIndex, $$v)},expression:"checkAll[cIndex]"}},[_vm._v(_vm._s(category.name))])],1),_c('section',[_c('ul',_vm._l((category.permissions),function(permission,index){return _c('li',{key:index,staticClass:"el-col el-col-24 el-col-xs-12 el-col-sm-12 el-col-md-10 el-col-lg-12"},[_c('el-checkbox',{attrs:{"label":permission._id},on:{"change":function($event){return _vm.handleChangePermission(
                                '' + category._id,
                                category.name
                              )}},model:{value:(_vm.managePermissions.userPermission),callback:function ($$v) {_vm.$set(_vm.managePermissions, "userPermission", $$v)},expression:"managePermissions.userPermission"}},[_vm._v(_vm._s(permission.name))])],1)}),0)])],2)],1)]})],2)],1)])],1)],1),(_vm.getManagePermissionsErrors &&
        _vm.getManagePermissionsErrors.critical_error
        )?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getManagePermissionsErrors.critical_error)+" ")]):_vm._e(),(!_vm.isFromEntities)?_c('div',{staticClass:"card-footer bg-white"},[_c('el-form-item',{staticClass:"mb-0 p-2"},[_c('el-button',{attrs:{"plain":""},on:{"click":_vm.resetForm}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary","disabled":!_vm.isUserRoleSelected},on:{"click":_vm.onSubmit}},[_vm._v("Save Changes")])],1)],1):_vm._e(),(_vm.isFromEntities)?_c('div',{staticClass:"float-right"},[_c('el-form-item',{staticClass:"mb-0 p-2"},[_c('el-button',{on:{"click":_vm.closeEntityPermissions}},[_vm._v("Cancel")]),_c('el-button',{staticClass:"type-2",staticStyle:{"background-color":"#409eff","color":"#ffffff"},attrs:{"loading":_vm.loading},on:{"click":_vm.saveEntityPermissions}},[_vm._v("Save")])],1)],1):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }